import React, { Component } from 'react'

import QueryResults from "../Reusable/QueryResult"

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

//Declare variables and constants
const limit = 10;
const pageCount = 1;

export default class BusqAvanzada extends Component {
    render() {
        return (
            <ProductConsumer>
                {context => {
                    return (
                        <QueryResults lista={context.lista} total={context.total} limit={limit} pageCount={pageCount} searchType="Avanzada"/>
                    )
                }}
            </ProductConsumer>
        )
    }
}
