//Import >React Library
import React, { Component } from 'react';

//Imports icons
import LeftArrow from "./Icons/PreviousPage"
import RightArrow  from "./Icons/NextPage"

import First  from "./Icons/FirstPage"
import  Last  from "./Icons/LastPage"


//Set width of the icon
var iconWidth = "30px"
var iconWidth2 = "50px"

//Creates an icon list
export const IconList = [
    {
  
        LeftArrow: <LeftArrow stroke="#ffffff"  width={iconWidth} />,
        RightArrow: <RightArrow stroke="#ffffff" width={iconWidth} />,
  
       
        First: <First stroke="#ffffff" width={iconWidth} />,
        Last: <Last stroke="#ffffff" width={iconWidth} />
      
    }
]
