import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

//Import material design components
import { MDBTooltip, MDBBtn, MDBCol, MDBRow } from "mdbreact";

//Import icon reusable component

import BusqInteligenteToolbar from "./BusqInteligenteToolbar"

import lvroIcon from "../../assets/icons/lvro.js"
import NewsIcon from "../../assets/icons/news.js"

import { css } from "@emotion/core";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";

const override = css`
  display: block;
  margin: auto auto;
  margin-top: 20vh!important;
  border-color: red;
`;

export default class QuerySmartResult extends Component {
    render() {
        return (
            <ProductConsumer>
                {context => {
                    const rowsPerPage = this.props.limit;
                    var content = "default"
                    if (context.lista !== null) {

                        if (context.lista === "cargando") {
                            content = "loading"
                        }
                        else if (context.lista.length !== 0) {
                            content = "withData"
                        }
                        else {
                            content = "empty"
                        }
                    }
                    return (
                        <MDBCol className="noSpace justify-content-center">
                            {this.props.searchType === "Inteligente" && <BusqInteligenteToolbar total={this.props.total} limit={this.props.limit} pageCount={this.props.pageCount} isComplete={true} />}

                            <table className="eclex-table-container py-2  mx-auto">
                                <tr className="noSpace text-center ZoneBg eclex-scroll-row mt-0 mb-3" >
                                    <td size="2" className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title">Imagen digital</p>
                                    </td>
                                    <td size="2" className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title">Resumen</p>
                                    </td>
                                    {/*<td size="6" className="my-auto eclex-col-title ">
                                        <p className="noSpace eclex-table-title">Contenido</p>
                    </td>*/}
                                    <td size="2" className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title">Fecha</p>
                                    </td>
                                    <td size="3" className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title">Tipo</p>
                                    </td >
                                    <td className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title ">No. RO</p>
                                    </td>
                                    <td className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title ">No. Sup</p>
                                    </td>
                                    <td className="my-auto eclex-col-title">
                                        <p className="noSpace eclex-table-title ">Páginas</p>
                                    </td>
                                </tr>

                                {(content === "withData") ? (context.lista.slice((context.currentPage - 1) * rowsPerPage, (context.currentPage - 1) * rowsPerPage + rowsPerPage)).map(item =>
                                    <tr className="noSpace text-center eclex-scroll-row mb-4 py-4">
                                        <td size="2" className="my-auto eclex-col-result">
                                            <MDBTooltip

                                                tag="span"
                                                placement="top"
                                                className="eclex-tooltip-tohide"
                                            >
                                                <MDBBtn href={"https://lvro.finder.lexis.com.ec/?id=" + item.codigoRegistroOficialHash + "&type=%27%27&productName=ECLEX&page=%27%27"}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="lvroIcon"
                                                    color="transparent"
                                                    onMouseOver={e => (e.currentTarget.style.fill = "var(--mainZone)")}
                                                    onMouseOut={e => (e.currentTarget.style.fill = "var(--mainTotal)")}>
                                                    <lvroIcon maxWidth="70px" />
                                                </MDBBtn>
                                                <span>Ver imagen digitalizada</span>
                                            </MDBTooltip>

                                        </td>
                                        {parseInt(item.fecha.substr(0, 4)) >= 2000 ? <td size="2" className="my-auto eclex-col-result">
                                            <MDBTooltip

                                                tag="span"
                                                placement="top"
                                                className="eclex-tooltip-tohide"
                                            >
                                                <MDBBtn href={"http://esilecstorage.s3.amazonaws.com/biblioteca_silec/SILECNEWS/" + item.fecha.substr(0, 4) + "/RO" + item.fecha.split("-").join("") + ".html"}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="lvroIcon"
                                                    color="transparent"
                                                    onMouseOver={e => (e.currentTarget.style.fill = "var(--mainZone)")}
                                                    onMouseOut={e => (e.currentTarget.style.fill = "var(--mainTotal)")}>
                                                    <NewsIcon maxWidth="120px" />
                                                </MDBBtn>
                                                <span>Ver resumen</span>
                                            </MDBTooltip>

                                        </td> : <td> <p className="noSpace ">-</p></td>}

                                        {/* <td size="6" className="my-auto eclex-col-result">
                                            <p className="noSpace text-justify px-2 py-3">{item.textoContenido!==""?item.textoContenido:item.textoContenidoFT}</p>
                                </td>*/}

                                        <td size="2" className="my-auto eclex-col-result">
                                            <p className="noSpace ">{item.fecha}</p>
                                        </td>
                                        <td size="3" className="my-auto eclex-col-result ">
                                            <MDBTooltip
                                                tag="span"
                                                placement="top"
                                                className="eclex-tooltip-tohide"
                                            >
                                                <MDBBtn href={"https://lvro.finder.lexis.com.ec/?id=" + item.codigoRegistroOficialHash + "&type=%27%27&productName=ECLEX&page=%27%27"}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="eclex-tipo-complpeto-text"
                                                    color="transparent">
                                                    <p className="noSpace">{item.tipoCompleto}</p>
                                                </MDBBtn>
                                                <span>Ver imagen digitalizada</span>
                                            </MDBTooltip>

                                        </td>
                                        <td className="my-auto eclex-col-result">
                                            <p className="noSpace">{item.numeroRo}</p>
                                        </td>
                                        <td className="my-auto eclex-col-result ">
                                            <p className="noSpace">{item.numeroSuplemento}</p>
                                        </td>
                                        <td className="my-auto eclex-col-result ">
                                            <p className="noSpace">{item.totalPaginas}</p>
                                        </td>
                                    </tr>)
                                    : <React.Fragment />
                                }

                            </table>
                            {content === "empty" ? <p className="eclex-table-message">No existen resultados para su búsqueda</p> : <React.Fragment />}
                            {content === "loading" ? <MDBCol className="noSpace eclex-table-message mx-auto text-center justify-content-center">
                                <ClimbingBoxLoader
                                    css={override}
                                    size={15}
                                    color={"var(--mainTotal)"}
                                    loading={true}
                                />
                                <p className="">Cargando</p>
                            </MDBCol> : <React.Fragment />}


                            {content === "default" ? <p className="eclex-table-message">Por favor ingrese parámetros de búsqueda válidos</p> : <React.Fragment />}
                            {this.props.searchType === "Inteligente" && <BusqInteligenteToolbar total={this.props.total} limit={this.props.limit} pageCount={this.props.pageCount} isComplete={false} />}
                        </MDBCol>
                    )
                }}
            </ProductConsumer>

        )
    }
}
