//Import React Library
import React, { Component } from 'react'

//Import Material Design library
import { MDBTooltip, MDBContainer, MDBBtn } from "mdbreact";

//Define style
const style = { display: "inline-block", maxWidth: "50vh" };

export default class GenericIcon extends Component {
    componentDidMount() {
    }
    render() {
        return (
            <div style={style} className={"noSpace text-center " + this.props.estilo}>
                <MDBTooltip placement="bottom" domElement className="eclex-tooltip-tohide">{/*renders tooltip container*/}
                    <button className="eclex-pagination-icon GenericIcon py-1" onClick={this.props.clic} type="button" disabled={this.props.disabled}> {this.props.icon} </button>{/*Renders icon*/}
                    <div className="eclex-tooltip-tohide text-center">{this.props.tooltip}</div>{/*Renders tootip content*/}
                </MDBTooltip>
            </div>
        )
    }
}
