import React, { Component } from 'react'

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";
import { MDBRow, MDBCol, MDBTooltip } from 'mdbreact';

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

registerLocale('es', es)



export default class DateToolbar extends Component {



    render() {
        return (
            <ProductConsumer>
                {context => {


                    return (
                        <MDBRow className="noSpace  h-100 my-auto text-center py-2">
                            <MDBCol className="noSpace my-auto" size="6">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="eclex-tooltip-tohide2"
                                >
                                    <button className="eclex-tooltip-container">
                                        <DatePicker
                                            locale="es"
                                            selected={context.startRangeDate}
                                            onChange={date => context.handleStartRangeDate(date)}
                                            className="eclex-toolbar-input eclex-year-month-input m-auto"
                                            calendarClassName="dayCalendarPopper"
                                            minDate={new Date(1829, 11)}
                                            maxDate={new Date}
                                            selectsStart
                                            dateFormat="yyyy/MM/dd"
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText="Fecha de Inicio"
                                        >

                                        </DatePicker>
                                    </button>
                                    <span>"Ingrese el año, mes y día"</span>
                                </MDBTooltip>
                            </MDBCol>

                            <MDBCol className="noSpace my-auto" size="6">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="eclex-tooltip-tohide2"
                                >
                                    <button className="eclex-tooltip-container">
                                        <DatePicker
                                            locale="es"
                                            selected={context.endRangeDate}
                                            onChange={date => context.handleEndRangeDate(date)}
                                            minDate={context.startRangeDate}
                                            maxDate={new Date}
                                            selectsEnd
                                            showMonthDropdown
                                            useShortMonthInDropdown
                                            showYearDropdown
                                            dateFormat="yyyy/MM/dd"
                                            className="eclex-toolbar-input eclex-year-month-input m-auto"
                                            calendarClassName="dayCalendarPopper"
                                            placeholderText="Fecha de Fin"
                                            popperClassName="some-custom-class"
                                            popperPlacement="bottom-end"
                                            dropdownMode="select"
                                            popperModifiers={{
                                                preventOverflow: {
                                                    enabled: true,
                                                }
                                            }}
                                        >

                                        </DatePicker>
                                    </button>
                                    <span>"Ingrese el año, mes y día"</span>
                                </MDBTooltip>
                            </MDBCol>


                        </MDBRow>
                    )
                }}
            </ProductConsumer>
        )
    }
}
