//Import React Library
import React, { Component  } from 'react'


// Create Context Api (used for state management)
const ProductContext = React.createContext();
//parse url parameter
const queryString = require('query-string');
//jwt decode library
var jwtDecode = require('jwt-decode');
var token = "";
var productName = "";
const local = "http://192.168.100.21:80/"
const produccion = "https://serviceseclex.lexis.com.ec/"
const entorno = produccion
    
class ProductProvider extends Component {
    //State definition for all the app
    state = {
        url: "http://services.finder.lexis.com.ec/api/LamService/ValidateUser",
        urlRedirect: "/",
        collapseID: "0",
        producto: "",      
        isRoEnabled: false,
        modal: false,
        modalUrl: "",
        currentPage: 1, //Stores the current page of the pdf
        total: 1, //Stores total pages number
        view: "years",
        lista: [], //Stores the books information       
        showYears: true,
        showRO: false,
        startDate: null,
        startMonth: null,
        startDay: null,
        year: 2020,
        month: 0,
        day: 0,
        RoNum: 0,
        RoType: 0,
        startRangeDate: null,
        endRangeDate: null,
        smartInput: "",
        searchType:"",       
        apiUrl : {
            busqSimpleUrl: entorno + "api/v1/imageRo/get/",
            busqParcialUrl: entorno + "api/v1/imageRo/parcial/",
            busqPeriodoUrl: entorno + "api/v1/imageRo/periodo/",
            busqInteligenteUrl: entorno + "api/v1/imageRo/smartsearch/",
        
        }

    }

    

    async componentDidMount() {
        
        
        //console.log(this.state.apiUrl.servicesUrl)
        //console.log(sessionStorage.getItem('productName'))
        var urlInfo = queryString.parseUrl(window.location.href)
        if ((sessionStorage.getItem('lhl') === null) | (sessionStorage.getItem('lhl') === undefined)) {

            token = urlInfo.query.token
            //console.log(token)
        }
        else {
            token = sessionStorage.getItem('lhl');
            //console.log(token)
            //this.setState({ isRoEnabled: true });
        }

        if ((sessionStorage.getItem("productName") === null) | (sessionStorage.getItem('productName') === undefined)) {
            productName = urlInfo.query.productName;
            sessionStorage.setItem("productName", productName);
            //console.log(productName)
        }
        else {
            productName = sessionStorage.getItem("productName");
            //console.log(productName)
        }

        var datos = { "token": token }
        if ((productName !== null) & (productName !== undefined)) {
            //console.log(productName)
            if (productName === "LOYAL") {
                this.setState({ urlRedirect: "https://loyal.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
            }
            else if (productName === "TOTAL") {

                this.setState({ urlRedirect: "https://total.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
            }
            else if (productName === "LEGACY") {

                this.setState({ urlRedirect: "https://legacy.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
            }
            else if (productName === "Z-ONE") {

                this.setState({ urlRedirect: "https://zone.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
            }
        }
        if ((token !== null) & (token !== undefined) & (token !== "")) {
            // console.log("entra token dif null")
            await fetch("http://lam.finder.lexis.com.ec/api/v1/auth/validateToken", {
                // await fetch("http://192.168.85.128:49461/api/v1/auth/validateToken", {
                method: "post",
                body: JSON.stringify(datos),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json()
                            .catch(() => {
                                // Couldn't parse the JSON
                                //console.log(response.status);
                                this.setState({ isRoEnabled: false });

                                sessionStorage.setItem("lhl", null);
                            })
                            .then(({ message }) => {
                                // Got valid JSON with error response, use it
                                //console.log(message || response.status);
                                this.setState({ isRoEnabled: false });

                                sessionStorage.setItem("lhl", null);
                                /*this.setState(() => {
                                    return {
                                        colorAlert: "danger",
                                        textAlert: message,
                                        visibility: "visible"
                                    };
                                }); //Set alert fields according to fetch response (state=error)*/
                            });
                    }

                    // Successful response, parse the JSON and return the data
                    return response.json();
                })
                .then((json) => {
                    //console.log('parsed json', json)
                    if (json !== undefined) {
                        if (json.length === 0) {


                            var decoded = jwtDecode(token);
                            //console.log(decoded);
                            var producto = decoded.productName;
                            sessionStorage.setItem("productName", producto);
                            if (producto === "LOYAL") {
                                this.setState({ urlRedirect: "https://loyal.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
                            }
                            else if (producto === "TOTAL") {

                                this.setState({ urlRedirect: "https://total.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
                            }
                            else if (producto === "LEGACY") {

                                this.setState({ urlRedirect: "https://legacy.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
                            }
                            else if (producto === "Z-ONE") {

                                this.setState({ urlRedirect: "https://zone.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx" });
                            }
                            else {

                                this.setState({ urlRedirect: "https://finder.lexis.com.ec/Login/login.aspx" });
                            }

                            this.setState({ isRoEnabled: true });

                            sessionStorage.setItem("lhl", token);
                            /*this.setState(() => {
                                return {
                                    colorAlert: "success",
                                    textAlert: "Datos guardados exitosamente",
                                    visibility: "visible"
                                };
                            }); //Set alert fields according to fetch response (state=ok) */
                        }
                        else {
                            var strError = "";
                            //console.log(json[0].message);
                            json.forEach(element => {
                                strError = strError + ", " + element.message
                            });
                            //console.log(strError)
                            this.setState({ isRoEnabled: false });
                            sessionStorage.setItem("productName", null);
                        }
                    }
                    else {
                        this.setState({ isRoEnabled: false });
                        sessionStorage.setItem("productName", null);
                    }


                }
                )
        }



        /*curl --location --request POST 'http://localhost:49461/api/v1/auth/validateToken' \

--header 'Content-Type: application/json' \

--data-raw '{"token": "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImNlc2FyX3NldmlsbGFfZWNAaG90bWFpbC5jb20iLCJwcm9kdWN0TmFtZSI6IlRPVEFMIiwiZW5kRGF0ZSI6IjIwMjAtMDMtMjhUMTY6MzM6MzcuNzg5MTk3N1oifQ.5-YHib_sF6wi9punvq-bfS-2a8f7o1OTHxLC5Onzll4"}' */
    }
    //Shows/hides desired collapse container
    toggleCollapse = (collapseID) => {

        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));

    }

    toggleModal = (url) => {
        /*if(e!==undefined){
            e.preventDefault();
        }*/

        //console.log("modal state")
        this.setState({
            modal: !this.state.modal,
            modalUrl: url
        });
    }

    //Handles key pressed event
    handleKey = (key, total) => {
        //console.log(key)
        //Left key moves to the previous page
        if ((key == "left") && (this.state.currentPage > 1)) {
            this.handlePageChange(this.state.currentPage - 1, total)
        }
        //Right key moves to the next page
        else if ((key == "right") && (this.state.currentPage < total)) {
            this.handlePageChange(this.state.currentPage + 1, total)
        }
        else if ((key == 37) && (this.state.currentPage > 1)) {
            this.handlePageChange(this.state.currentPage - 1, total)
        }
        else if ((key == 39) && (this.state.currentPage < total)) {
            this.handlePageChange(this.state.currentPage + 1, total)
        }



    }

    //Moves to the page defined on the text input
    handlePageInputChange = (e, total) => {
        //console.log(e.target.value)
        if ((e.target.value <= total) && (e.target.value >= 1)) {
            //console.log("si entra")
            this.handlePageChange(parseInt(e.target.value), total)
        }
        else if (e.target.value === "") {
            this.setState({
                currentPage: "",

            });
        }

    }

    //Moves to the desired page
    handlePageChange = (page, total) => {
        //console.log(page)
        //console.log(total)
        if ((page <= total) && (page >= 1)) {
            this.setState({
                currentPage: page,

            });
            //console.log(page)
            /*//console.log(page)
            //console.log(this.state.books[page - 1].NombreArchivo)*/
        }
    };

   
    getList = async (year, month, day, type, number) => {
        this.setState({ view: "RO", showYears: false, lista: "cargando" });

    
        //console.log(year)
        //console.log(month)
        //console.log(day)

        //console.log(this.state.year)
        //console.log(this.state.month)
        //console.log(this.state.day)
        //var mes=month===undefined?1:month;
        var datos = { "listaRangos": [{ "anio": year, "mes": month, "dia": day }], "tipoCompleto": type, "numeroRo": number };
        await fetch(this.state.apiUrl.busqParcialUrl, {
            method: "post",
            body: JSON.stringify(datos),
            headers: { 'Content-Type': 'application/json' }
        })
            .then(response => {
                if (!response.ok) {
                    //console.log(response)
                    return response.json()
                        .catch(() => {
                            // Couldn't parse the JSON
                            //console.log(response.status);
                        })
                        .then((json) => {
                            // Got valid JSON with error response, use it
                            //console.log(json);

                            //alert("Servicio en Mantenimiento: cod " + response.status + ". " + json.Message)
                        });
                }
                else {
                    return response.json()
                        .then((json) => {
                            //console.log('parsed json', json.listaPaginasRO)
                            //console.log('parsed json', json)
                            if (json != undefined) {
                                this.setList(json);//Stores json data in states
                            }

                        })
                }

                // Successful response, parse the JSON and return the data
                //return response.json();
            })
        this.setState({ showRO: true });
    }


    setList = (lista) => {
        let tempLista = [];
        if (lista !== null) {
            lista.forEach(item => {
                const singleItem = { ...item };
                tempLista = [...tempLista, singleItem];

            })
            //console.log(lista)
            //console.log(tempLista.length)
            this.setState(() => {
                return {
                    lista: tempLista,
                    data: tempLista,
                    total: tempLista.length
                }
            })
        }
        else {
            this.setState(() => {
                return {
                    lista: null,
                    data: null,
                    total: 0
                }
            })

        }
    }

    handleYearChange = date => {
        if (date !== null) {
            //console.log(date.getFullYear())
            this.setState({
                year: date.getFullYear(),
                startDate: new Date(date.getFullYear(), this.state.month)
            });
            this.getList(date.getFullYear(), this.state.month, this.state.day, this.state.RoType, this.state.RoNum)
        }
    };

    handleYearChange2 = year => {
        var myYear = 0;
        if (year !== null) {
            myYear = year;
            this.setState({
                year: year,
                startDate: new Date(year, this.state.month)
            });
        }
        else {
            this.setState({
                year: myYear,
                startDate: null
            });
        }

        this.getList(myYear, this.state.month, this.state.day, this.state.RoType, this.state.RoNum)
    };

    handleMonthChange = date => {
        if (date !== null) {
            //console.log(date.getMonth() + 1)
            this.setState({
                month: date.getMonth() + 1,
                startMonth: new Date(this.state.year, date.getMonth())
            });
            this.getList(this.state.year, date.getMonth() + 1, this.state.day, this.state.RoType, this.state.RoNum)
        }
        else {
            this.setState({
                month: date,
                startMonth: null
            });
            this.getList(this.state.year, 0, this.state.day, this.state.RoType, this.state.RoNum)
        }


    };

    handleDayChange = date => {
        //console.log(date)
        if (date !== null) {
            //console.log(date.getDate())
            this.setState({
                day: date.getDate(),
                startDay: new Date(this.state.year, this.state.month, date.getDate())
            });
            this.getList(this.state.year, this.state.month, date.getDate(), this.state.RoType, this.state.RoNum)
        }
        else {
            this.setState({
                day: date,
                startDay: null
            });
            this.getList(this.state.year, this.state.month, 0, this.state.RoType, this.state.RoNum)
        }


    };

    handleMonthChange2 = date => {
        if (date !== null) {
            //console.log(date.getMonth() + 1)
            this.setState({
                month: date.getMonth() + 1,
                //startDate: new Date(this.state.year, date.getMonth())
            });
            this.getList(this.state.year, date.getMonth() + 1, this.state.RoType, this.state.RoNum)
        }
    };

    handleTypeROChange = e => {
        var type = e.target.value;
        //console.log(type)


        this.setState({
            RoType: type
        });
        this.getList(this.state.year, this.state.month, this.state.day, type, this.state.RoNum)


    }
    handleNumROChange = (e, searchType) => {
        var number = parseInt(e.target.value);
        //console.log(number)
        //console.log(searchType)
        if (number !== null) {

            this.setState({
                RoNum: number,
                searchType:searchType
            }, this.getListOnNumROChange);

        }
        else {
            this.setState({
                RoNum: 0
            }, this.getListOnNumROChange);
           

        }

    }

    getListOnNumROChange = () => {
        if (this.state.searchType === "Simple") { this.getList(this.state.year, this.state.month, this.state.day, this.state.RoType, this.state.number) }
        else { this.getRangeList() }
    }
    handleStartRangeDate = date => {
        this.setState({
            startRangeDate: date
        }, this.getRangeList
        );

    }

    handleEndRangeDate = date => {
        this.setState({
            endRangeDate: date
        }, this.getRangeList
        );

    }

    setBusqSimpleView = view => {
        //console.log("setting iew: ", view)
        this.setState({
            view: view,
            showYears: true,
            showRO: false
        },
            this.setList(null)
        );

    }
    getRangeList = async () => {
        //console.log(this.state.startRangeDate)
        //console.log(this.state.endRangeDate)
       
        //var mes=month===undefined?1:month;
        if ((this.state.startRangeDate !== null) && (this.state.endRangeDate !== null)) {
             this.setState({
            lista: "cargando"
        }
        )
            var datos = { "listaRangos": [{ "anio": this.state.startRangeDate.getFullYear(), "mes": this.state.startRangeDate.getMonth() + 1, "dia": this.state.startRangeDate.getDate() }, { "anio": this.state.endRangeDate.getFullYear(), "mes": this.state.endRangeDate.getMonth() + 1, "dia": this.state.endRangeDate.getDate() }], "tipoCompleto": this.state.RoType, "numeroRo": this.state.RoNum };
            //console.log(datos)
            await fetch(this.state.apiUrl.busqPeriodoUrl, {
                method: "post",
                body: JSON.stringify(datos),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    if (!response.ok) {
                        //console.log(response)
                        return response.json()
                            .catch(() => {
                                // Couldn't parse the JSON
                                //console.log(response.status);
                            })
                            .then((json) => {
                                // Got valid JSON with error response, use it
                                //console.log(json);

                                //alert("Servicio en Mantenimiento: cod " + response.status + ". " + json.Message)
                            });
                    }
                    else {
                        return response.json()
                            .then((json) => {
                                //console.log('parsed json', json.listaPaginasRO)
                                //console.log('parsed json', json)
                                if (json != undefined) {
                                    this.setList(json);//Stores json data in states
                                }

                            })
                    }

                    // Successful response, parse the JSON and return the data
                    //return response.json();
                })
        }
    }

    handleSmartInput = (e) => {
        this.setState({

            smartInput: e.target.value
        }
        )
        //var mes=month===undefined?1:month;

    }

    querySmartSearch = async () => {
        this.setState({
            lista: "cargando"
        }
        )
        var text = this.state.smartInput;
        if ((text !== null) && (text.length >= 1)) {
            var datos = { "consulta": text }
            //console.log(datos)
            await fetch(this.state.apiUrl.busqInteligenteUrl, {
                method: "post",
                body: JSON.stringify(datos),
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    if (!response.ok) {
                        //console.log(response)
                        return response.json()
                            .catch(() => {
                                // Couldn't parse the JSON
                                //console.log(response.status);
                            })
                            .then((json) => {
                                // Got valid JSON with error response, use it
                                //console.log(json);

                                //alert("Servicio en Mantenimiento: cod " + response.status + ". " + json.Message)
                            });
                    }
                    else {
                        return response.json()
                            .then((json) => {
                                //console.log('parsed json', json.listaPaginasRO)
                                //console.log('parsed json', json)
                                if (json != undefined) {
                                    this.setList(json);//Stores json data in states
                                }

                            })
                    }

                    // Successful response, parse the JSON and return the data
                    //return response.json();
                })
        }
    }
    render() {
        return (
            <ProductContext.Provider
                value={{
                    ...this.state,
                    toggleCollapse: this.toggleCollapse,
                    toggleModal: this.toggleModal,
                    handleKey: this.handleKey,
                    handlePageChange: this.handlePageChange,
                    handlePageInputChange: this.handlePageInputChange,
                    getList: this.getList,
                    handleYearChange: this.handleYearChange,
                    handleYearChange2: this.handleYearChange2,
                    handleMonthChange: this.handleMonthChange,
                    handleMonthChange2: this.handleMonthChange2,
                    handleDayChange: this.handleDayChange,
                    handleNumROChange: this.handleNumROChange,
                    handleTypeROChange: this.handleTypeROChange,
                    handleStartRangeDate: this.handleStartRangeDate,
                    handleEndRangeDate: this.handleEndRangeDate,
                    setBusqSimpleView: this.setBusqSimpleView,
                    handleSmartInput: this.handleSmartInput,
                    querySmartSearch: this.querySmartSearch
                }} // Enable access to states and functions from ProductProvider
            >
                {this.props.children}
              
            </ProductContext.Provider>
        )
    }
}

//create context objet for consumer
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
