import React, { Component } from 'react'

export default class news extends Component {
  render() {
    return (
     
       
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.maxWidth}
        x="0"
        y="0"
        version="1.1"
        viewBox="0 0 1275 450"
        xmlSpace="preserve"
      >
        <g transform="translate(-168.25 293.9)">
          <path d="M173.7-293.6l-5 443.4H391C522.7 98.8 527.8-8.8 527.8-8.8v-284.8zM483.3.2s-19.3 49.4-76.4 21c0 0 30.2 66.4-25.2 86.6H213.1v-359.3h270.2z"></path>
          <path d="M250.9 -194H443V-161.6H250.9z"></path>
          <path d="M250.9 -124.5H443V-92.1H250.9z"></path>
          <path d="M250.9 -54.9H443V-22.5H250.9z"></path>
          <path
            fill="#fff"
            d="M342.6 -32.5H1442.8000000000002V155.8H342.6z"
          ></path>
          <g transform="translate(0 -517.2)">
            <path d="M400.4 532.7h23.1v84h52.3v21h-75.4z"></path>
            <path d="M493.3 532.7h79.2v20.5h-56.2v21.3h49.5V595h-49.5v22h57v20.5h-79.9V532.7z"></path>
            <path d="M622.4 584.3l-35.1-51.6h27l21.9 34 22.3-34h26.2l-35 51.3 36.6 53.7h-27l-23.5-36-23.7 36h-26.2z"></path>
            <path d="M704.6 532.7h23.1v105h-23.1z"></path>
            <path d="M746.8 622.4l13.6-16.3c9.5 7.8 19.3 12.7 31.3 12.7 9.5 0 15.1-3.8 15.1-9.9v-.3c0-5.8-3.6-8.8-21.1-13.3-21.1-5.4-34.8-11.3-34.8-32.1v-.3c0-19 15.3-31.6 36.7-31.6 15.3 0 28.3 4.8 39 13.3l-12 17.4c-9.3-6.4-18.4-10.3-27.3-10.3-8.9 0-13.5 4-13.5 9.1v.3c0 6.9 4.5 9.1 22.6 13.8 21.3 5.5 33.3 13.2 33.3 31.5v.3c0 20.8-15.9 32.5-38.5 32.5-15.8 0-31.8-5.6-44.4-16.8z"></path>
            <path d="M898 536.1c0-2.1 1.8-3.9 3.9-3.9h1.2c1.6 0 2.8.9 3.9 2.3l70.8 89.8v-88.5c0-2.1 1.6-3.8 3.7-3.8s3.8 1.7 3.8 3.8v99c0 1.8-1.3 3.3-3.1 3.3h-.6c-1.5 0-2.7-1-3.9-2.5l-72.1-91.5v90.4c0 2.1-1.6 3.7-3.7 3.7s-3.8-1.6-3.8-3.7v-98.4z"></path>
            <path d="M1016.5 633.8v-97.2c0-2.1 1.8-3.9 3.9-3.9h67.8c1.9 0 3.6 1.7 3.6 3.6 0 1.9-1.7 3.6-3.6 3.6h-63.9v41.2h57.1c2 0 3.6 1.7 3.6 3.6 0 2-1.6 3.6-3.6 3.6h-57.1v42.1h64.6c1.9 0 3.6 1.6 3.6 3.6 0 1.9-1.7 3.6-3.6 3.6h-68.5c-2.1.1-3.9-1.7-3.9-3.8z"></path>
            <path d="M1110.8 538.1c-.3-.8-.4-1.4-.4-2 0-2.1 1.9-4 4-4s3.6 1.8 4.2 3.6l31.9 90.4 29.8-91c.6-1.7 1.6-3 3.8-3h.3c2 0 3.1 1.3 3.8 3l29.8 91 32.1-90.9c.8-1.9 2-3.1 3.8-3.1 2 0 3.9 2 3.9 3.9 0 .6-.3 1.4-.6 2.1l-35.1 97.3c-.8 2-2 3.5-4 3.5h-.3c-2.1 0-3.5-1.5-4-3.5l-29.7-88.2-29.7 88.2c-.6 2-2 3.5-4 3.5h-.3c-2.1 0-3.3-1.4-4-3.5z"></path>
            <path d="M1274.9 624.5c-.8-.6-1.5-1.7-1.5-3 0-2.1 1.8-3.9 3.9-3.9 1 0 2 .5 2.5.9 10.5 9.4 21 13.6 35.4 13.6 15.8 0 26.5-8.8 26.5-20.8v-.3c0-11.1-5.8-17.5-29.7-22.3-25.2-5.1-35.4-13.6-35.4-29.5v-.3c0-15.6 14.2-27.6 33.7-27.6 14.1 0 23.8 3.6 33.4 10.8.8.6 1.6 1.7 1.6 3.2 0 2.1-1.8 3.9-3.9 3.9-.9 0-1.8-.3-2.5-.9-9.2-7-18.3-9.9-28.9-9.9-15.4 0-25.6 8.8-25.6 19.8v.3c0 11.1 5.7 17.8 30.6 22.9 24.3 4.9 34.5 13.6 34.5 28.9v.3c0 16.9-14.5 28.6-34.8 28.6-15.6 0-27.9-5-39.8-14.7z"></path>
          </g>
        </g>
      </svg>
    )
  }
}
