import React from "react";

function Icon(props) {
  return (
    <svg
      width={props.width}
      height={props.width}
      fill="none"
      stroke={props.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      className="feather feather-arrow-right-circle"
      version="1.1"
      viewBox="0 0 24.4 24.4"
    >
      <g transform="translate(-11.805 -13.023) scale(2.10191)">
        <path d="M12 16L16 12 12 8"></path>
        <path d="M12 16L16 12 12 8" transform="translate(-5.151)"></path>
      </g>
    </svg>
  );
}

export default Icon;
