import React, { Component } from 'react'

//Import icon reusable component
import MyPagination from "./MyPagination"
import SmartToolbar from "./SmartToolbar"

import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
export default class BusqInteligenteToolbar extends Component {
    render() {
        return (
            <MDBRow className="noSpace eclex-toolbar-container  w-100 mx-auto mt-3 eclex-section-collapse py-2 ">
            <MDBCol className="noSpace" size="12" md="5" lg="4">
                {this.props.isComplete&&<SmartToolbar />}
            </MDBCol>
            <MDBCol className="noSpace" size="12" md="7" lg="4">
                <MyPagination total={this.props.total} limit={this.props.limit} pageCount={this.props.pageCount} />
            </MDBCol>
            <MDBCol className="noSpace h-100 my-auto py-2 d-none d-lg-block" size="12" lg="4">
                
            </MDBCol>
        </MDBRow> 

           
        )
    }
}