//Import React Library
import React, { Component } from 'react'

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

//import react-router
import { Switch, Route } from "react-router-dom";

//import layouts
import Home from "../Layouts/Home";
import BusqSimple from "../Layouts/BusqSimple"
import BusqAvanzada from "../Layouts/BusqAvanzada"


const Content = () => {
   

    return (
        <React.Fragment>                  
                
                <Switch>
                    <Route exact path="/" component={Home} />                   
                    <Route path="/busqueda-simple" component={BusqSimple} />
                    <Route path="/busqueda-avanzada" component={BusqAvanzada} />
                    <Route path="/busqueda-avanzada" component={BusqAvanzada} />
                    <Route component={Home} />
                </Switch>
        </React.Fragment>
    );
};

export default Content;