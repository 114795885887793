import React, { Component } from 'react'

export default class pymes extends Component {
   render() {
      return (
         <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 185.64 175.828"
            width={this.props.maxWidth}
            xmlSpace="preserve"
         >
            <defs>
               <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
                  <path d="M0 131.871h139.23V0H0z"></path>
               </clipPath>
            </defs>
            <g
               
               transform="matrix(1.33333 0 0 -1.33333 0 175.828)"
            >
               <g transform="translate(100.247 59.268)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c-.103-.048-.138-.077-.174-.079a8.86 8.86 0 00-.489-.005C-7.106.009-13.53-.159-19.896-1.309c-4.547-.821-8.97-2.02-13.095-4.156-.671-.347-1.316-.742-1.973-1.115C-22.303-3.535-9.439-3.487 3.491-3.607v54.904H0zm-81.637 51.309V-3.443c6.486-.162 12.922-.227 19.348-.512 6.433-.285 12.807-1.13 19.094-2.673-1.13.691-2.269 1.351-3.463 1.889a44.41 44.41 0 01-11.914 3.463c-2.414.352-4.831.751-7.261.897-3.498.209-7.008.21-10.512.294-.585.014-1.17.002-1.822.002v51.392zm5.961-49.003c2.314 0 4.539.075 6.756-.021 2.215-.097 4.424-.348 6.633-.562A59.51 59.51 0 00-51.574-.311c3.6-1.032 7.039-2.429 10.192-4.48.331-.215.66-.434 1.072-.705v.593l.001 29.852c0 6.731-.004 13.462.007 20.193.001.46-.128.793-.441 1.14-1.665 1.845-3.659 3.228-5.882 4.302-4.034 1.948-8.361 2.778-12.763 3.29-3.852.447-7.723.587-11.6.562-1.544-.01-3.088-.001-4.688-.001zM-2.468 54.35c-.226.012-.385.03-.544.027-4.278-.079-8.56-.078-12.833-.266-4.056-.177-8.058-.825-11.945-2.054-3.667-1.16-6.995-2.887-9.628-5.777-.294-.321-.434-.638-.434-1.083.013-8.479.009-16.958.01-25.436V-5.473c.198.108.291.148.372.204 4.007 2.787 8.487 4.48 13.203 5.546a78.408 78.408 0 007.75 1.332c2.926.359 5.874.563 8.819.739 1.724.103 3.458.019 5.23.019zm-86.107-5.417h4.446v4.917h5.97c0 .798.029 1.544-.009 2.285-.027.511.156.66.656.675 4.748.137 9.495.207 14.24-.114 4.54-.306 9.04-.857 13.407-2.198 4.008-1.232 7.664-3.084 10.613-6.151.041-.042.096-.069.164-.117.73.645 1.434 1.318 2.191 1.925 2.662 2.137 5.721 3.492 8.969 4.45 3.886 1.146 7.867 1.767 11.903 1.978 3.541.186 7.087.264 10.633.333 1.605.031 3.213-.085 4.821-.112.445-.008.607-.165.588-.617-.033-.749-.01-1.499-.01-2.304h5.929v-4.928h4.485v-59.083h-.659c-14.808-.001-29.616-.003-44.424.006-.459 0-.786-.076-1.081-.477-.486-.661-1.206-.951-2.022-.963-.856-.014-1.713-.005-2.569-.001-.866.005-1.599.318-2.12 1.018-.247.331-.524.424-.926.423-14.847-.01-29.693-.008-44.539-.008h-.656z"
                  ></path>
               </g>
               <g transform="translate(112.289 63.376)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c.042 7.733-6.187 14.083-13.833 14.102-7.691.02-14.058-6.288-14.053-13.921.006-7.643 6.189-13.912 13.785-13.975C-6.339-13.859-.042-7.699 0 0m1.097-17.678l-4.298 4.29c-4.445-3.334-9.421-4.457-14.837-3.178-4.18.987-7.515 3.317-9.993 6.833-4.993 7.084-3.879 16.866 2.568 22.659 6.417 5.767 16.204 5.883 22.689.359C3.717 7.757 5.815-2.647-.646-10.951l4.557-4.133c-.329-.077-.187-.087-.116-.021 2.384 2.188 2.394 2.2 4.681-.077 4.387-4.37 8.767-8.749 13.139-13.134 1.504-1.508 1.486-1.776-.037-3.289-1.255-1.247-2.511-2.493-3.765-3.741-1.538-1.529-1.798-1.533-3.276-.057-4.424 4.418-8.853 8.829-13.269 13.255-2.124 2.128-2.112 2.14-.171 4.47"
                  ></path>
               </g>
               <g transform="translate(113.046 63.53)">
                  <path
                     fill="#fff"
                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0-8.119-6.582-14.701-14.701-14.701-8.118 0-14.7 6.582-14.7 14.701s6.582 14.7 14.7 14.7C-6.582 14.7 0 8.119 0 0"
                  ></path>
               </g>
               <g transform="translate(15.76 40.959)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .626.496 1.121 1.122 1.121.626 0 1.121-.495 1.121-1.121v-7.122h4.151c.567 0 1.019-.451 1.019-1.02 0-.567-.452-1.019-1.019-1.019H1.122C.496-9.161 0-8.666 0-8.04z"
                  ></path>
               </g>
               <g transform="translate(24.777 32.92)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v7.952c0 .627.495 1.121 1.121 1.121h5.651c.554 0 1.005-.451 1.005-1.005a.994.994 0 00-1.005-.99H2.228V5.01h3.889c.553 0 1.005-.451 1.005-1.005a.995.995 0 00-1.005-.99H2.228V.874h4.617C7.398.874 7.85.422 7.85-.131a.995.995 0 00-1.005-.991H1.121C.495-1.122 0-.626 0 0"
                  ></path>
               </g>
               <g transform="translate(34.318 33.604)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0l2.752 3.379L.16 6.539c-.175.219-.335.51-.335.831 0 .626.466 1.106 1.107 1.106.48 0 .757-.189 1.034-.553l2.228-2.971 2.214 2.913c.305.393.597.611 1.092.611.495 0 1.02-.393 1.02-1.034 0-.32-.117-.583-.335-.859L5.592 3.408 8.331.043c.174-.218.335-.509.335-.83a1.08 1.08 0 00-1.107-1.106c-.481 0-.758.189-1.035.553L4.136 1.792l-2.36-3.074c-.305-.393-.597-.611-1.092-.611-.495 0-1.019.393-1.019 1.034 0 .32.116.582.335.859"
                  ></path>
               </g>
               <g transform="translate(44.98 40.959)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .626.495 1.121 1.122 1.121.626 0 1.121-.495 1.121-1.121v-8.127c0-.626-.495-1.121-1.121-1.121C.495-9.248 0-8.753 0-8.127z"
                  ></path>
               </g>
               <g transform="translate(49.568 32.876)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0a1.046 1.046 0 00-.422.845c0 .582.466 1.033 1.048 1.033.292 0 .496-.101.641-.204.772-.567 1.617-.917 2.608-.917.917 0 1.47.364 1.47.961v.03c0 .567-.349.858-2.053 1.295C1.238 3.568-.087 4.136-.087 6.16v.029c0 1.85 1.485 3.073 3.568 3.073 1.238 0 2.315-.32 3.233-.903a1.02 1.02 0 00.495-.888c0-.582-.466-1.034-1.048-1.034a1.07 1.07 0 00-.569.161c-.742.436-1.441.684-2.14.684-.859 0-1.311-.393-1.311-.888v-.03c0-.67.437-.888 2.199-1.34 2.068-.539 3.234-1.281 3.234-3.058v-.029c0-2.024-1.544-3.161-3.743-3.161A6.578 6.578 0 000 0"
                  ></path>
               </g>
               <g transform="translate(66.188 32.526)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0l-3.306 7.908c-.073.175-.131.35-.131.539a1.1 1.1 0 001.122 1.107c.582 0 .961-.335 1.135-.786L1.369 2.17l2.578 6.67c.145.364.539.714 1.077.714.612 0 1.107-.466 1.107-1.078 0-.175-.058-.364-.116-.495L2.68 0c-.233-.554-.656-.889-1.267-.889h-.146C.655-.889.233-.554 0 0"
                  ></path>
               </g>
               <g transform="translate(74.14 40.959)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .626.495 1.121 1.122 1.121.626 0 1.121-.495 1.121-1.121v-8.127c0-.626-.495-1.121-1.121-1.121C.495-9.248 0-8.753 0-8.127z"
                  ></path>
               </g>
               <g transform="translate(78.933 32.92)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v7.952c0 .627.495 1.121 1.122 1.121h5.65c.554 0 1.005-.451 1.005-1.005a.994.994 0 00-1.005-.99H2.229V5.01h3.888c.553 0 1.005-.451 1.005-1.005a.995.995 0 00-1.005-.99H2.229V.874h4.616C7.398.874 7.85.422 7.85-.131a.995.995 0 00-1.005-.991H1.122C.495-1.122 0-.626 0 0"
                  ></path>
               </g>
               <g transform="translate(88.051 40.566)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0a1.477 1.477 0 00-.087.422c0 .626.524 1.092 1.15 1.092.525 0 .947-.335 1.093-.801L4.18-5.535 6.219.655c.175.524.568.888 1.136.888h.175c.568 0 .961-.349 1.136-.888l2.039-6.19L12.729.713c.146.437.524.801 1.078.801.611 0 1.107-.466 1.107-1.077 0-.131-.044-.306-.087-.437l-2.855-8.011c-.204-.568-.626-.917-1.165-.917h-.233c-.539 0-.947.335-1.151.917L7.414-2.17 5.403-8.011c-.203-.582-.611-.917-1.15-.917H4.02c-.539 0-.961.349-1.165.917z"
                  ></path>
               </g>
               <g transform="translate(18.596 25.693)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c.686 0 1.078.365 1.078.904v.019c0 .603-.42.913-1.105.913h-1.398V0zm-2.832 2.403c0 .393.311.703.704.703h2.22c.813 0 1.443-.228 1.864-.648.356-.356.547-.859.547-1.462V.978c0-1.005-.502-1.626-1.278-1.947l.977-1.187c.137-.174.229-.32.229-.539 0-.393-.329-.649-.667-.649-.32 0-.531.156-.695.365l-1.37 1.736h-1.124v-1.398a.698.698 0 00-.703-.703.698.698 0 00-.704.703z"
                  ></path>
               </g>
               <g transform="translate(22.37 23.107)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v4.988c0 .393.311.704.704.704h3.545c.347 0 .63-.283.63-.63a.624.624 0 00-.63-.622H1.398V3.143h2.439a.632.632 0 00.631-.631.624.624 0 00-.631-.621H1.398V.548h2.896a.632.632 0 00.631-.631.624.624 0 00-.631-.621H.704A.699.699 0 000 0"
                  ></path>
               </g>
               <g transform="translate(28.21 25.583)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v.019c0 1.818 1.416 3.307 3.353 3.307.968 0 1.608-.219 2.184-.621a.694.694 0 00-.403-1.261c-.2 0-.328.073-.429.146-.384.274-.795.439-1.398.439-1.023 0-1.836-.905-1.836-1.992V.019c0-1.17.804-2.029 1.937-2.029.511 0 .968.128 1.324.384v.914h-.95a.604.604 0 00-.612.603c0 .338.274.611.612.611h1.609c.392 0 .703-.31.703-.703v-1.553c0-.411-.156-.694-.493-.895a4.256 4.256 0 00-2.239-.64C1.37-3.289 0-1.891 0 0"
                  ></path>
               </g>
               <g transform="translate(35.72 28.15)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .393.311.704.704.704A.698.698 0 001.407 0v-5.098a.697.697 0 00-.703-.703.698.698 0 00-.704.703z"
                  ></path>
               </g>
               <g transform="translate(38.599 23.08)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0a.659.659 0 00-.265.53c0 .366.293.649.658.649a.675.675 0 00.402-.128C1.279.695 1.809.475 2.431.475c.575 0 .923.229.923.603v.019c0 .356-.22.539-1.289.813-1.288.329-2.12.685-2.12 1.955v.018c0 1.16.932 1.928 2.239 1.928.776 0 1.453-.201 2.028-.566a.642.642 0 00.311-.558.648.648 0 00-.658-.648.678.678 0 00-.357.1c-.466.274-.904.429-1.342.429-.54 0-.823-.246-.823-.557v-.018c0-.42.274-.557 1.38-.841 1.297-.338 2.028-.804 2.028-1.919v-.018c0-1.27-.968-1.982-2.348-1.982C1.562-.767.713-.511 0 0"
                  ></path>
               </g>
               <g transform="translate(45.927 27.502)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0h-1.352a.651.651 0 00-.649.648c0 .357.293.649.649.649h4.111a.651.651 0 00.649-.649A.651.651 0 002.759 0H1.407v-4.45a.698.698 0 00-.703-.703A.698.698 0 000-4.45z"
                  ></path>
               </g>
               <g transform="translate(53.191 25.693)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c.686 0 1.078.365 1.078.904v.019c0 .603-.42.913-1.105.913h-1.398V0zm-2.832 2.403c0 .393.311.703.703.703h2.22c.814 0 1.444-.228 1.864-.648.357-.356.548-.859.548-1.462V.978c0-1.005-.502-1.626-1.279-1.947l.978-1.187c.137-.174.228-.32.228-.539 0-.393-.328-.649-.666-.649-.32 0-.531.156-.695.365l-1.37 1.736h-1.124v-1.398a.698.698 0 00-.704-.703.698.698 0 00-.703.703z"
                  ></path>
               </g>
               <g transform="translate(62.009 25.583)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v.019c0 1.096-.804 2.01-1.937 2.01-1.132 0-1.918-.896-1.918-1.992V.019c0-1.097.804-2.011 1.937-2.011C-.786-1.992 0-1.096 0 0m-5.326 0v.019c0 1.818 1.434 3.307 3.408 3.307 1.973 0 3.389-1.471 3.389-3.289V.019c0-1.819-1.434-3.308-3.408-3.308-1.973 0-3.389 1.471-3.389 3.289"
                  ></path>
               </g>
               <g transform="translate(72.517 25.583)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v.019c0 1.096-.804 2.01-1.937 2.01-1.132 0-1.918-.896-1.918-1.992V.019c0-1.097.804-2.011 1.937-2.011C-.786-1.992 0-1.096 0 0m-5.326 0v.019c0 1.818 1.434 3.307 3.408 3.307 1.973 0 3.389-1.471 3.389-3.289V.019c0-1.819-1.434-3.308-3.408-3.308-1.973 0-3.389 1.471-3.389 3.289"
                  ></path>
               </g>
               <g transform="translate(75.241 28.096)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .393.311.704.704.704h3.581c.356 0 .64-.284.64-.64a.636.636 0 00-.64-.639H1.407v-1.362h2.467a.635.635 0 00.639-.64.635.635 0 00-.639-.639H1.407v-1.827a.698.698 0 00-.703-.704.699.699 0 00-.704.704z"
                  ></path>
               </g>
               <g transform="translate(81.3 28.15)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .393.311.704.704.704A.698.698 0 001.407 0v-5.098a.697.697 0 00-.703-.703.698.698 0 00-.704.703z"
                  ></path>
               </g>
               <g transform="translate(84.022 25.583)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0v.019c0 1.818 1.371 3.307 3.335 3.307.959 0 1.608-.256 2.138-.649a.704.704 0 00.274-.557.686.686 0 00-.695-.685.736.736 0 00-.42.137c-.393.292-.804.457-1.306.457-1.078 0-1.855-.896-1.855-1.992V.019c0-1.097.758-2.011 1.855-2.011.593 0 .986.183 1.388.503.11.092.256.156.421.156a.66.66 0 00.657-.649.662.662 0 00-.237-.503c-.576-.502-1.252-.804-2.275-.804C1.398-3.289 0-1.836 0 0"
                  ></path>
               </g>
               <g transform="translate(91.113 28.15)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .393.311.704.704.704A.698.698 0 001.407 0v-5.098a.698.698 0 00-.703-.703.698.698 0 00-.704.703z"
                  ></path>
               </g>
               <g transform="translate(97.72 25.08)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0l-.886 2.11L-1.772 0zm-3.956-1.773l2.229 5.034c.156.348.439.558.823.558h.082c.384 0 .658-.21.813-.558L2.22-1.773a.676.676 0 00.073-.283.666.666 0 00-.667-.676c-.329 0-.548.192-.676.484L.521-1.243h-2.814l-.448-1.05a.69.69 0 00-.649-.439.648.648 0 00-.648.658c0 .1.037.201.082.301"
                  ></path>
               </g>
               <g transform="translate(101.12 28.15)">
                  <path

                     fillOpacity="1"
                     fillRule="nonzero"
                     stroke="none"
                     d="M0 0c0 .393.311.704.704.704A.698.698 0 001.407 0v-4.467h2.604a.635.635 0 00.639-.64.636.636 0 00-.639-.64H.704A.699.699 0 000-5.043z"
                  ></path>
               </g>
            </g>
         </svg>
      )
   }
}
