//Import react library
import React, { Component } from 'react'
//Import Collapse reusable component
//import Collapse from "./Collapse"
//Import material design components
import { MDBCol, MDBRow, MDBTypography, MDBIcon, MDBCollapse, MDBTooltip } from "mdbreact";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"


//Import icons
import Simple from "../../assets/icons/BusqSimple.js"
import Avanzada from "../../assets/icons/BusqAvanzada.js"
import Inteligente from "../../assets/icons/BusqInteligente.js"
import Help from "../../assets/icons/help.js"
//import EstadisticasIcon from "../../assets/icons/Estadisticas.js"

//import layouts
import BusqSimple from "./BusqSimple"
import BusqAvanzada from "./BusqAvanzada"
import BusqInteligente from './BusqInteligente';
import Estadisticas from "./Estadisticas"

//import scroll 
import {
    scroller
} from "react-scroll";

import tipsBusquedaSimple from "../../assets/data/tips_busqueda_simple.pdf"
import tipsBusquedaAvanzada from "../../assets/data/tips_busqueda_avanzada.pdf"
import tipsBusquedaInteligente from "../../assets/data/tips_busqueda_inteligente.pdf"
import tipsEstadisticas from "../../assets/data/tips_estadisticas.pdf"

const folders = [
    {
        id: 0,
        icon: <Simple />,
        name: "Búsqueda Simple",
        tooltip: "Búsqueda Simple por fecha",
        tips: tipsBusquedaSimple

    },
    {
        id: 1,
        icon: <Avanzada />,
        //clic: console.log(2),
        name: "Búsqueda Avanzada",
        tooltip: "Búsqueda Avanzada por período",
        tips: tipsBusquedaAvanzada,

    },
    {
        id: 2,
        icon: <Inteligente />,
        //clic: console.log(2),
        name: "Búsqueda Inteligente",
        tooltip: "Búsqueda Inteligente por palabra",
        tips: tipsBusquedaInteligente,
    },
   /* {
        id: 3,
        icon: <EstadisticasIcon />,
        //clic: console.log(2),
        name: "Estadísticas",
        tooltip: "Estadísticas mensuales",
        tips: tipsEstadisticas,
    }*/
]

export default class Hfr extends Component {

    state = {
        toggleId: 0,
        toggleYearId: 0,
        selectedFolder: "",
        tip: "",
        folderId: 0

    }
    //Shows/hides desired collapse container
    toggleCollapse = (toggleId, name, value, tip, id) => {
        //console.log(tip)
        if (value !== undefined) { value.setBusqSimpleView("years") }

        /* var tempBooks = NIIFbooks;
         if (name === "NIIF") { tempBooks = NIIFbooks }
         else if (name === "CINIIF") { tempBooks = CINIIFbooks }
         else if (name === "NIC") { tempBooks = NICbooks }
         else if (name === "SIC") { tempBooks = SICbooks }
         else if (name === "NIIF-PYMES") { tempBooks = PYMESbooks }
         else if (name === "Nuevas Reformas") { tempBooks = Reformasbooks }*/
        //console.log(tip)
        this.setState(prevState => ({
            tip: tip,
            toggleId: toggleId,
            selectedFolder: name,
            folderId: id
        }));


    }


    //Shows/hides desired collapse container
    setFolder = (name) => {

        this.setState({ selectedFolder: name });

    }


    componentDidMount() {
        this.toggleCollapse(0);

    }

    render() {
        return (
            <ProductConsumer>
                {value => //value contains the data defined in the product provider in context.js
                (
                    <React.Fragment>                        
                        
                        <MDBCollapse id={0} isOpen={this.state.toggleId} onOpened={() => scroller.scrollTo(0, {
                            duration: 2500,
                            delay: 0,
                            smooth: "easeInOutQuart",
                            offset: -80
                        })}>
                            <MDBRow className="noSpace w-100 eclex-folder-container" center>

                                {folders.map(folder =>
                                    <MDBCol key={folder.id} size="6" sm="4" md="3" lg="2" className="noSpace text-center">{/*Container for vademecum section */}
                                        <MDBTooltip
                                            domElement
                                            tag="span"
                                            placement="top"
                                            className="eclex-tooltip-tohide"
                                        >
                                            <button className="niifLogo w-75"

                                                onMouseOver={e => (e.currentTarget.style.fill = "var(--mainZone)")}
                                                onMouseOut={e => (e.currentTarget.style.fill = "var(--mainTotal)")}
                                                onClick={() => this.toggleCollapse(1, folder.name, undefined, folder.tips, folder.id)}>
                                                {folder.icon}
                                            </button>
                                            <span>{folder.tooltip}</span>
                                        </MDBTooltip>
                                    </MDBCol>)}
                            </MDBRow>
                        </MDBCollapse>

                        <MDBCollapse id={1} isOpen={this.state.toggleId} className="" onOpened={() => scroller.scrollTo(1, {
                            duration: 800,
                            delay: 0,
                            smooth: "easeInOutQuart",
                            offset: -80
                        })}>
                            <button
                                className="btn eclex-section-collapse m-auto w-100"
                                onClick={() => (this.toggleCollapse(0, "", value, this.state.tip, 0))}
                            >
                                <div className="row mx-0 px-0 px-sm-5 text-white py-2">

                                    <h3 className="noSpace text-left my-auto">{this.state.selectedFolder}</h3>
                                    <MDBTooltip

                                        tag="span"
                                        placement="top"
                                        className="eclex-tooltip-tohide2"
                                    >
                                        <a className="eclex-help-icon my-auto"
                                            href={this.state.tip}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <Help />
                                        </a>
                                        <span>Tips de Uso</span>
                                    </MDBTooltip>
                                    <MDBCol className="px-0"></MDBCol>
                                    <MDBIcon icon="angle-down" size="2x" className="my-auto"></MDBIcon>
                                </div>

                            </button>


                        </MDBCollapse>
                        {this.state.selectedFolder === "Búsqueda Simple" && <BusqSimple />}
                        {this.state.selectedFolder === "Búsqueda Avanzada" && <BusqAvanzada />}
                        {this.state.selectedFolder === "Búsqueda Inteligente" && <BusqInteligente />}
                        {/*this.state.selectedFolder === "Estadísticas" && <Estadisticas />*/}

                    </React.Fragment>
                )
                }
            </ProductConsumer>
        )
    }
}
