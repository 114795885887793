//Import React Library
import React from 'react'
//Import styles
import './css/App.css';

//Import components
import Content from "./Components/MainDrawer/Content"

import { ProductProvider } from './Context/context'; //Import context api
import { BrowserRouter as Router } from 'react-router-dom'; // Import elements for routing

import { css } from "@emotion/core";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { MDBCol } from "mdbreact";
const override = css`
  display: block;
  margin: auto auto;
  margin-top: 20vh!important;
  border-color: red;
`;

function App() {
 

  
    return (
      <ProductProvider>
        <Router>
        <Content />
        </Router>
        </ProductProvider>
     
    );
 
}

export default App;
