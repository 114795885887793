import React, { Component } from 'react'
//import timeline
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

//Import material design components
import { MDBCol, MDBRow, MDBIcon, MDBCollapse } from "mdbreact";

//import scroll 
import {
    scroller
} from "react-scroll";


// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

//import icon list
import { IconList } from "../Reusable/IconList"

//Import icon reusable component
import QueryResults from "../Reusable/QueryResult"


import Fade from 'react-reveal/Fade';

const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step));

const decades = [
    {
        decade: "Década actual",
        start: 2021,
        end: 2021,
        years: range(2021, 2021, 1)

    },
    {
        decade: "2011-2020",
        start: 2011,
        end: 2020,
        years: range(2011, 2020, 1)

    },
    {
        decade: "2001-2010",
        start: 2001,
        end: 2010,
        years: range(2001, 2010, 1)
    },
    {
        decade: "1991-2000",
        start: 1991,
        end: 2000,
        years: range(1991, 2000, 1)
    },
    {
        decade: "1981-1990",
        start: 1981,
        end: 1990,
        years: range(1981, 1990, 1)
    },
    {
        decade: "1971-1980",
        start: 1971,
        end: 1980,
        years: range(1971, 1980, 1)
    },
    {
        decade: "1961-1970",
        start: 1961,
        end: 1970,
        years: range(1961, 1970, 1)
    },
    {
        decade: "1951-1960",
        start: 1951,
        end: 1960,
        years: range(1951, 1960, 1)
    },
    {
        decade: "1941-1950",
        start: 1941,
        end: 1950,
        years: range(1941, 1950, 1)
    },
    {
        decade: "1931-1940",
        start: 1931,
        end: 1940,
        years: range(1931, 1940, 1)
    },
    {
        decade: "1921-1930",
        start: 1921,
        end: 1930,
        years: range(1921, 1930, 1)
    },
    {
        decade: "1911-1920",
        start: 1911,
        end: 1920,
        years: range(1911, 1920, 1)
    },
    {
        decade: "1901-1910",
        start: 1901,
        end: 1910,
        years: range(1901, 1910, 1)
    }
    ,
    {
        decade: "antes de 1900",
        start: 1830,
        end: 1990,
        years: [1830, 1835, 1843, 1845, 1851, 1852, 1861, 1869, 1878, 1884, 1895, 1896, 1897, 1898, 1899, 1990]
    }

]

//Declare variables and constants
const limit = 10;
const pageCount = 1;

export default class BusqSimple extends Component {
    state = {

        toggleYearId: 0,
        selectedFolder: "",

        // books: NIIFbooks
    }

    //Shows/hides desired collapse container for years classification
    toggleYearCollapse = (toggleYearId) => {

        this.setState(prevState => ({
            toggleYearId: prevState.toggleYearId !== toggleYearId ? toggleYearId : ""

        }));

    }




    render() {
        return (
            <ProductConsumer>
                {context => {
                    return (
                        <React.Fragment>
                            {context.view === "years" ?
                                <Fade when={context.showYears}>
                                    <VerticalTimeline layout="1-column">
                                        {decades.map(decade => {
                                            return (
                                                <VerticalTimelineElement
                                                    className="vertical-timeline-element--work"
                                                    contentStyle={{ background: '#00acbd', color: '#fff' }}
                                                    contentArrowStyle={{ borderRight: '7px solid  #00acbd', top: "25px" }}
                                                    //date="2011 - present"
                                                    iconStyle={{ background: '#00acbd', color: '#ffffff', fontSize: "27px", width: "40px", height: "40px", top: "11.5px" }}
                                                    icon={<MDBIcon icon="landmark" className="m-auto" />}
                                                >
                                                    <button
                                                        className="btn eclex-year-btn pt-3 pb-2 h-100 w-100"
                                                        onClick={() => this.toggleYearCollapse(decade.decade)}
                                                    >
                                                        <MDBRow className="mx-0">
                                                            <h4 className="vertical-timeline-element-title text-white">{decade.decade}</h4>
                                                            <MDBCol className="px-0"></MDBCol>
                                                            <MDBIcon icon="angle-down" size="2x" className="my-auto"></MDBIcon>
                                                        </MDBRow>
                                                    </button>
                                                    <MDBCollapse id={decade.decade} isOpen={this.state.toggleYearId} className="eclex-yaer-collapse-content" >
                                                        <MDBRow className="noSpace pb-4">
                                                            {decade.years.map(year =>
                                                                <div className="col-md-15 text-center pt-4 pb-0">
                                                                    <button className="btn eclex-text-collapse-content w-75 "
                                                                        onClick={() => (context.handleYearChange2(year))}>{year}</button>
                                                                </div>
                                                            )}
                                                        </MDBRow>
                                                    </MDBCollapse>
                                                    {/*<h4 className="vertical-timeline-element-subtitle">{this.state.selectedFolder +" del año" +year.year}</h4>*/}

                                                </VerticalTimelineElement>)
                                        })}

                                    </VerticalTimeline>

                                </Fade>



                                :
                                <Fade when={context.showRO}>
                                    <QueryResults lista={context.lista} total={context.total} limit={limit} pageCount={pageCount} searchType="Simple"/>
                                </Fade>
                            }

                        </React.Fragment>

                    )
                }}
            </ProductConsumer>
        )
    }
}
