import React, { Component } from 'react'

import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import "react-datepicker/dist/react-datepicker.css";
import { MDBRow, MDBCol, MDBTooltip } from 'mdbreact';

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context"

registerLocale('es', es)



export default class DateToolbar extends Component {



    render() {
        return (
            <ProductConsumer>
                {context => {


                    return (
                        <MDBRow className="noSpace  h-100 my-auto text-center py-2">
                            <MDBCol className="noSpace my-auto" size="4">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="eclex-tooltip-tohide2"
                                >
                                    <button className="eclex-tooltip-container">
                                        <DatePicker
                                            locale="es"
                                            selected={context.startDate}
                                            onChange={date => context.handleYearChange(date)}
                                            showYearPicker
                                            dateFormat="yyyy"
                                            className="eclex-toolbar-input eclex-year-month-input m-auto"
                                            calendarClassName="calendarPopper"
                                           
                                            maxDate={new Date()}


                                            placeholderText="Todos"
                                        >
                                            <button className="eclex-toolbar-todos-btn" onClick={() => context.handleYearChange2(null)}>Todos</button>
                                        </DatePicker>
                                    </button>
                                    <span>"Elija un año"</span>
                                </MDBTooltip>

                            </MDBCol>
                            <MDBCol className="noSpace my-auto" size="4">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="eclex-tooltip-tohide2"
                                >
                                    <button className="eclex-tooltip-container">
                                        <DatePicker
                                            locale="es"
                                            selected={context.startMonth}
                                            onChange={context.handleMonthChange}
                                            renderCustomHeader={({

                                            }) => (
                                                    <p className="noSpace">Seleccione un mes</p>
                                                )}
                                            dateFormat="MM"
                                            showMonthYearPicker
                                            showPopperArrow={false}
                                            className="eclex-toolbar-input eclex-year-month-input m-auto"
                                            calendarClassName="monthCalendarPopper"
                                            placeholderText="Todos"
                                            popperPlacement="bottom-center"
                                            popperModifiers={{

                                                preventOverflow: {
                                                    enabled: true,

                                                }
                                            }}
                                        >
                                            <button className="eclex-toolbar-todos-btn" onClick={() => context.handleMonthChange(null)}>Todos</button>
                                        </DatePicker>
                                    </button>
                                    <span>"Elija un mes"</span>
                                </MDBTooltip>

                            </MDBCol>
                            <MDBCol className="noSpace my-auto" size="4">
                                <MDBTooltip
                                    domElement
                                    tag="span"
                                    placement="top"
                                    className="eclex-tooltip-tohide2"
                                >
                                    <button className="eclex-tooltip-container">
                                        <DatePicker
                                            locale="es"
                                            selected={context.startDay === null ? null : (((context.year === null) || (context.month === null)) ? new Date(2020, context.month - 1, 1) : new Date(context.year, context.month - 1, context.day))}

                                            onChange={context.handleDayChange}
                                            renderCustomHeader={({

                                            }) => (
                                                    <p className="noSpace w-100">Seleccione un día</p>
                                                )}
                                            dateFormat="dd"
                                            className="eclex-toolbar-input eclex-year-month-input m-auto"
                                            calendarClassName="dayCalendarPopper"
                                            placeholderText="Todos"
                                            /* minDate={((context.year===0)||(context.month===0))?new Date(2020,1,1):new Date(context.year,context.month,1)}
                                             maxDate={((context.year===0)||(context.month===0))?new Date(2020,1,31):new Date(context.year,context.month+1,0)}*/
                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={{

                                                preventOverflow: {
                                                    enabled: true,

                                                }
                                            }}
                                        >
                                            <button className="eclex-toolbar-todos-btn" onClick={() => context.handleDayChange(null)}>Todos</button>
                                        </DatePicker>
                                    </button>
                                    <span>"Elija un día"</span>
                                </MDBTooltip>


                            </MDBCol>


                        </MDBRow>
                    )
                }}
            </ProductConsumer>
        )
    }
}
