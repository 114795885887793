import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 50 50"
            xmlSpace="preserve"
        >
            <g transform="matrix(.04694 0 0 .04694 1.53 1.53)">
                <path d="M500 10C227 10 10 227 10 500s217 490 490 490 490-217 490-490S773 10 500 10zm0 910C269 920 80 731 80 500S269 80 500 80s420 189 420 420-189 420-420 420z"></path>
                <path d="M542 633h-70v-28c0-49 21-91 63-126 49-35 70-70 70-105 0-56-28-77-84-84-56 0-91 35-112 105l-70-21c21-105 84-154 196-154 91 7 147 56 154 140 0 56-28 105-91 147-42 28-63 63-56 98v28zm7 147h-84v-84h84z"></path>
            </g>
        </svg>
    );
}

export default Icon;