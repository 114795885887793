import React from "react";

function Icon(props) {
  return (
    <svg
      width={props.width}
      height={props.width}
      fill="none"
      stroke={props.stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      className="feather feather-arrow-left-circle"
      version="1.1"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 8L8 12 12 16"
        transform="translate(-9.027 -13.223) scale(2.10191)"
      ></path>
    </svg>
  );
}

export default Icon;
